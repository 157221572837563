import React from "react";
import { useNavigate } from "react-router-dom";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { allowedUsers } from "../allowedUsers";

import { ReactComponent as GoogleIcon } from "../icons/flat-color-icons_google.svg";

const GoogleSignIn = () => {
  const navigate = useNavigate();

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (allowedUsers.includes(user.email)) {
        navigate("/home");
      } else {
        alert("Tu cuenta no está autorizada para acceder a esta aplicación.");
        await auth.signOut();
      }
    } catch (error) {
      console.error("Error en la autenticación:", error);
    }
  };

  return (
    <div className="Signin-content">
      <div className="card-signin">
      <div className="top">
        <h1 className="title">
          Document
          <br />
          Management APP
        </h1>
      </div>
      <div className="bottom">
        <p className="welcome">Welcome back! Please log in to continue </p>
        <div className="separator"></div>
        <button className="btn-signin" onClick={handleSignIn}>
          <GoogleIcon width={30} height={30} />
          <p className="btn-text">Log in with Google</p>
        </button>
      </div>
    </div>
    </div>
  );
};

export default GoogleSignIn;
