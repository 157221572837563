import React from "react";

import { ReactComponent as CloseIcon } from "../../icons/material-symbols_close.svg";
import QRCodeGenerator from "../QRCodeGenerator";

function ModalQrViewer({ setModalViewQr, url, fileName, setIsShadow }) {
  const handleCloseModal = () => {
    setModalViewQr(false);
    setIsShadow(false);
  };

  return (
    <div className="modal modal-qr-viewer">
      <button className="btn-close" onClick={handleCloseModal}>
        <CloseIcon />
        <span className="text">Cerrar</span>
      </button>
      <hr />
      <div className="content">
      <QRCodeGenerator url={url} fileName={fileName} />
      </div>
    </div>
  );
}

export default ModalQrViewer;
