import React from "react";
import UploadFile from "../UploadFile";
import { ReactComponent as CloseIcon } from "../../icons/material-symbols_close.svg";

export const ModalNewDocument = ({ setModalShow, setIsShadow }) => {
  const handleCloseModal = () => {
    setModalShow(false);
    setIsShadow(false);
  };

  return (
    <div className="modal modal-new-document">
      <button className="btn-close" onClick={handleCloseModal}>
        <CloseIcon />
        <span className="text">Cerrar</span>
      </button>
      <hr />
      <UploadFile />
    </div>
  );
};
