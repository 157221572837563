import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { db, storage } from "../firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";

import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";

const PDFViewer = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfName, setPdfName] = useState("pdfdownload.pdf");
  const { fileKey } = useParams();
  const navigate = useNavigate();

  async function getDocumentById() {
    const docRef = doc(db, "files", fileKey);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Documento encontrado");
      return docSnap.data();
    } else {
      navigate("/");
      console.log("No se encontró ningún documento con el ID especificado");
      return null;
    }
  }

  const downloadPDF = () => {
    const pdfRef = ref(storage, "documents/".concat(pdfName));

    getDownloadURL(pdfRef)
      .then((url) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobURL = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = blobURL;
            link.setAttribute("download", pdfName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            console.error("Error al obtener el archivo:", error);
          });
      })
      .catch((error) => {
        console.error("Error al obtener la URL de descarga:", error);
      });
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getDocumentById();
      if (data != null) {
        setPdfName(data.name);
        setPdfUrl(data.url);
      }
    }
    fetchData();
  }, []);

  if (pdfUrl) {
    return (
      <div className="PdfViewer-content">
        <div className="container">
        <button className="btn-download-pdf" onClick={downloadPDF}>
          Descargar PDF
        </button>
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={pdfUrl} defaultScale={SpecialZoomLevel.PageWidth} />
        </Worker>
        </div>
      </div>
    );
  }

  return <div>Cargando...</div>;
};

export default PDFViewer;
