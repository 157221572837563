import React, { useState } from "react";

import { Header } from "./Header";
import { DocumentList } from "./DocumentList";

import { ReactComponent as PlusIcon } from "../../icons/ic_baseline-plus.svg";
import { ModalNewDocument } from "./ModalNewDocument";

const Home = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isShadow, setIsShadow] = useState(false);

  const handleNewDocument = () => {
    setModalShow(true);
    setIsShadow(true);
  };

  return (
    <div className="Home-content">
      <Header />
      {modalShow ? <ModalNewDocument setModalShow={setModalShow} setIsShadow={setIsShadow} /> : <span></span>}
      <main className="main">
        <div className={`${isShadow && 'shadow'}`}></div>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="title">DOCUMENTOS</h1>
          <button className="btn-new-document" onClick={handleNewDocument}>
            <PlusIcon />
            <span className="text">Nuevo Documento</span>
          </button>
          <DocumentList setIsShadow={setIsShadow} />
        </div>
      </main>
    </div>
  );
};

export default Home;
