import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { auth } from "../firebaseConfig";
import { signOut } from "firebase/auth";

const RouteNotMapped = () => {
  useEffect(() => {
    handleSignOut();
    return () => <Navigate to="/" />;
  }, [handleSignOut]);

  return <Navigate to="/" />;
};

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      console.log("Sesión cerrada exitosamente");
    })
    .catch((error) => {
      console.error("Error al cerrar sesión:", error);
    });
};

export default RouteNotMapped;
