import React from "react";
import { Navigate } from "react-router-dom";

import { auth } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (user) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
