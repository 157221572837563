import React, { useState } from "react";

function InputFilePdf({ setFile, setProgress }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);

  // Manejador para archivos arrastrados
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    // Obtiene el archivo arrastrado
    const files = event.dataTransfer.files;
    if (files && files[0] && files[0].type === "application/pdf") {
      setSelectedFile(files[0]);
      setFile(files[0]);
      setProgress(0);
    } else {
      alert("Por favor, selecciona un archivo PDF");
    }
  };

  // Manejador para seleccionar el archivo a través de clic
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      setFile(file);
      setProgress(0);
    } else {
      alert("Por favor, selecciona un archivo PDF");
    }
  };

  // Manejador para el clic que abre el selector de archivos
  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className="flex flex-col items-center">
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleButtonClick}
        className={`card-input-upload-file border-2 border-dashed rounded-lg p-4 cursor-pointer transition-colors ${
          dragActive
            ? "bg-blue-100 border-blue-500"
            : "bg-gray-100 border-gray-400"
        }`}
      >
        {selectedFile ? (
          <p className="text-center">{selectedFile.name}</p>
        ) : (
          <div className="input-upload-file">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="text-center text-gray-600">
              Arrastra y suelta un archivo PDF aquí o haz clic para seleccionar
              uno
            </p>
          </div>
        )}
      </div>

      {/* Input oculto para seleccionar el archivo mediante clic */}
      <input
        id="fileInput"
        type="file"
        accept="application/pdf"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default InputFilePdf;
