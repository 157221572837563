import React, { useRef } from "react";

import { QRCodeCanvas } from "qrcode.react";

const QRCodeGenerator = ({ url, fileName }) => {
  const qrRef = useRef();

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const image = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    const link = document.createElement("a");
    link.href = image;
    link.download = "QR_".concat(fileName.split('.')[0]).concat('.png');
    link.click();
  };

  return (
    <div>
      <div className="qr-section" ref={qrRef}>
        <QRCodeCanvas
          className="m-auto"
          value={url}
          size={350}
          bgColor="#ffffff"
          fgColor="#000000"
          level="L"
          marginSize={5}
        />
      </div>
      <button className="btn-qr-download" onClick={handleDownload}>
        Descargar QR
      </button>
    </div>
  );
};

export default QRCodeGenerator;
