import React, { useState } from "react";

import { storage, db } from "../../firebaseConfig";
import { ref, deleteObject } from "firebase/storage";
import { doc, deleteDoc } from "firebase/firestore";

import ModalQrViewer from "./ModalQrViewer";

import { ReactComponent as DocumentIcon } from "../../icons/solar_document-bold.svg";
import { ReactComponent as QRIcon } from "../../icons/bx_qr.svg";
import { ReactComponent as PdfPreviewIcon } from "../../icons/mdi_eye.svg";
import { ReactComponent as DeleteIcon } from "../../icons/mdi_trash.svg";

export const DocumentItem = ({ name, url, setIsShadow, docId }) => {
  const [modalViewQr, setModalViewQr] = useState(false);

  const deleteDocToStorage = async () => {
    const filePath = "documents/".concat(name);
    const fileRef = ref(storage, filePath);

    try {
      await deleteObject(fileRef);
      console.log("Archivo eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el archivo", error);
    }
  };

  const deleteDocToFirestore = async () => {
    const docRef = doc(db, "files", docId);

    try {
      await deleteDoc(docRef);
      console.log("Documento eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el documento", error);
    }
  };

  const handleShowModalQrViewer = () => {
    setModalViewQr(true);
    setIsShadow(true);
  };

  const handleDeletePdf = () => {
    let msg = '¿Seguro que quiere eliminar "'.concat(name).concat('"?');
    if (window.confirm(msg)) {
      deleteDocToStorage();
      deleteDocToFirestore();
      console.log("Yes confirm");
    }
  };

  return (
    <div className="card-document">
      {modalViewQr ? (
        <ModalQrViewer
          setModalViewQr={setModalViewQr}
          url={url}
          fileName={name}
          setIsShadow={setIsShadow}
        />
      ) : (
        <span></span>
      )}
      <DocumentIcon />
      <span className="card-text text-gray-800 ml-3 mr-5">{name}</span>
      <div className="card-actions">
        <button
          className="btn-action btn-qr-generate"
          onClick={handleShowModalQrViewer}
        >
          <QRIcon title="Generate QR" />
        </button>
        <div className="separator"></div>
        <a
          className="btn-action btn-pdf-preview"
          href={url.replace("download", "pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PdfPreviewIcon title="Preview PDF" />
        </a>
        <div className="separator"></div>
        <button className="btn-action btn-delete-pdf" onClick={handleDeletePdf}>
          <DeleteIcon title="Delete PDF" />
        </button>
      </div>
    </div>
  );
};
