import React, { useEffect, useState } from "react";

import { db } from "../../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";

import { DocumentItem } from "./DocumentItem";

export const DocumentList = ({ setIsShadow }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const basePath = window.location.origin;

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = files.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "files"), (snapshot) => {
      const files = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFiles(files);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <h1 className="mt-4">Cargando...</h1>;
  } else {
    return (
      <div className="list-content">
        <input
          type="text"
          placeholder="Buscar por nombre"
          value={searchTerm}
          onChange={handleChange}
          className="search-input"
        />
        <div className="document-list mb-9">
          {filteredData.map((file) => (
            <DocumentItem
              key={file.id}
              name={file.name}
              url={basePath.concat("/download/").concat(file.id)}
              setIsShadow={setIsShadow}
              docId={file.id}
            />
          ))}
        </div>
      </div>
    );
  }
};
